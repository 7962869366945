<template>
    <header class="header">
        <div class="container">
            <div class="row">
                <router-link :to="{ name: 'home' }">
                    <img :src="logo" alt="Baltic Expo" class="header__logo">
                </router-link>
                <div class="header__text" v-if="$route.name === 'home'">Reserve your place(s)</div>
                <div class="header__text" v-else-if="$route.name === 'booking'">Finalize your booking</div>
                <app-button @click="logout" class="header__logout" v-if="$route.name === 'admin'">Log Out</app-button>
            </div>
        </div>
    </header>
</template>

<script setup>
import logo from '../assets/logo.svg'
import AppButton from './Button.vue'
import { useUserStore } from '../store/user'

const userStore = useUserStore()

const logout = async () => {
    try {
        await userStore.logOut()
        router.push({ name: 'login' })
    }
    catch (err) {
        console.log(err)
    }
}
</script>

<style lang="sass" scoped>
.header
    .row
        align-items: center
        height: 59px
        gap: 16px

    &__logo
        height: 35px

    &__text
        font-size: 14px
        font-weight: 600
        padding: 0 0 4px 0

    &__logout
        margin-left: auto
</style>