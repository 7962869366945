import { defineStore } from 'pinia'
import { getAuth, signOut, setPersistence, browserSessionPersistence, signInWithEmailAndPassword } from 'firebase/auth'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null
    }),
    actions: {
        async getUser () {
            const auth = getAuth()

            await auth.onAuthStateChanged(user => {
                if (user) {
                    this.user = user
                }
            })

            return
        },
        async logIn (email, password){
            const auth = getAuth()

            try {
                await setPersistence(auth, browserSessionPersistence)
                const { user } = await signInWithEmailAndPassword(auth, email, password)

                this.user = user
            } catch (error) {
                console.log(error)
            }
        },
        async logOut () {
            const auth = getAuth()

            await signOut(auth)

            this.user = null
        }
    }
})