import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../store/user'
import Admin from '../views/admin/index.vue'
import AdminSeats from '../views/admin/Seats.vue'
import AdminOrders from '../views/admin/Orders.vue'
// import Landing from '../views/landing/index.vue'
import Home from '../views/home/index.vue'
import Booking from '../views/booking/index.vue'
import ThankYou from '../views/thank-you/index.vue'
import Login from '../views/admin/Login.vue'

const routes = [
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        redirect: {
            name: 'AdminSeats'
        },
        beforeEnter: async (to, from, next) => {
            if (useUserStore().user) {
                next()
                return
            }

            await useUserStore().getUser()

            if (useUserStore().user) {
                next()
            } else {
                next({ name: 'login' })
            }
        },
        children: [
            {
                path: 'seats',
                name: 'AdminSeats',
                component: AdminSeats,
            },
            {
                path: 'orders',
                name: 'AdminOrders',
                component: AdminOrders,
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: async (to, from, next) => {
            if (!useUserStore().user) {
                next()
                return
            }

            await useUserStore().getUser()

            if (useUserStore().user) {
                next({ name: 'admin' })
            } else {
                next()
            }
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/booking',
        name: 'booking',
        component: Booking
    },
    {
        path: '/thank-you',
        name: 'thank-you',
        component: ThankYou
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router