<template>
    <div
        class="tooltip"
        :style="
            {
                'left': `${tooltip.position.x}px`,
                'top': `${tooltip.position.y}px`
            }
        "
        v-show="tooltip.visible"
    >
        <div class="tooltip__status" :class="`tooltip__status--${tooltip.status}`">
            <p>#{{ tooltip.number }}</p>
            <p>{{ tooltip.status }}</p>
        </div>

        <div class="tooltip__info">
            <p v-if="isAvailable" class="tooltip__price">{{ tooltip.price }} EUR</p>
            <p class="tooltip__dimensions">Dimensions: 3x2 m</p>
            <app-button v-if="isAvailable" secondary @click="seats.selectSeat(tooltip.id)">{{ buttonText }}</app-button>
        </div>

        <div class="tooltip__arrow"></div>
    </div>
</template>

<script setup>
import AppButton from './Button.vue'
import { useTooltipStore } from '../store/tooltip'
import { useSeatsStore } from '../store/seats'
import { computed } from 'vue'

const seats = useSeatsStore()
const tooltip = useTooltipStore()
const isAvailable = computed(() => {
    return tooltip.status === 'available'
})
const isSelected = computed(() => {
    return seats.selected.find(item => item.id === tooltip.id)
})
const buttonText = computed(() => {
    if (!isAvailable.value) {
        return 'BOOKED'
    }
    if (isSelected.value) {
        return 'DESELECT'
    }
    return 'SELECT PLACE'
})
</script>

<style lang="sass" scoped>
.tooltip
    position: absolute
    transform: translate(-50%, -144%)
    filter: drop-shadow(1px 1px 8px rgba(41, 41, 41, 0.35))
    z-index: 5

    @media only screen and (max-width: 767px)
        display: block
        position: absolute
        top: 20px
        left: 20px
        transform: none
        top: 8px !important
        left: 8px !important
        filter: none
        border: 1px solid #e1e5e9
        border-radius: 8px

    &::before
        content: ''
        position: absolute
        bottom: 0
        left: 50%
        height: 32px
        width: 32px
        border-radius: 8px
        background: #fff
        transform: translate(-50%, -12px) rotate(-45deg)
        filter: drop-shadow(1px 1px 8px rgba(41, 41, 41, 0.35))

        @media only screen and (max-width: 767px)
            display: none !important

    &__status
        display: flex
        align-items: center
        justify-content: space-between
        font-size: 12px
        line-height: 15px
        font-weight: 500
        text-transform: capitalize
        padding: 8px
        border-radius: 8px 8px 0 0

        &--available
            background: var(--available)

        &--reserved
            background: var(--reserved)

        &--booked
            background: var(--booked)

    &__info
        position: relative
        padding: 8px
        border-radius: 0 0 8px 8px
        background: #fff

    &__price
        font-size: 14px
        line-height: 17px
        font-weight: 500

    &__dimensions
        font-size: 10px
        line-height: 12px
        white-space: nowrap
        color: #7E7E7E
        margin: 0 0 12px 0

    &__arrow
        height: 24px
        width: 100%
        @media only screen and (max-width: 767px)
            display: none !important

    button
        min-width: 134px
        @media only screen and (max-width: 767px)
            line-height: 32px

        &[disabled]
            opacity: .2
            pointer-events: none
</style>