<template>
    <div class="admin">
        <div class="container container--full">
            <div class="admin__nav">
                <router-link :to="{name: 'AdminSeats'}" class="admin__nav-btn">Floor Plan</router-link>
                <router-link :to="{name: 'AdminOrders'}" class="admin__nav-btn">Orders</router-link>

                <div class="admin__orders-buttons">
                    <a v-if="$route.name === 'AdminOrders'" class="admin__nav-btn" @click="showAllOrders">Show All Orders</a>
                    <a v-if="$route.name === 'AdminOrders'" class="admin__nav-btn" @click="copyAllEmails">Copy All E-mails</a>
                </div>
            </div>

            <router-view></router-view>
        </div>
    </div>
</template>

<script setup>
import FloorPlan from '../../components/FloorPlan.vue'
import AppButton from '../../components/Button.vue'
import { useSeatsStore } from '../../store/seats'
import { useOrdersStore } from '../../store/orders'
import { copyTextToClipboard } from '../../composables/useHelpers'
import { reactive } from 'vue'

const seats = useSeatsStore()
const orders = useOrdersStore()

let selectedSeat = reactive({})

const seatClicked = seatId => {
    seats.resetSelectedSeats()
    seats.selectSeat(seatId)
    Object.assign(selectedSeat, seats.selectedSeat(seatId))
}

const saveSeat = e => {
    e.preventDefault()
    seats.updateSeat(selectedSeat)
}

const showAllOrders = () => {
    orders.showAll = true
}

const copyAllEmails = async () => {
    let emails = ''

    seats.all.forEach(seat => {
        if (seat.accountId && !emails.includes(seat.accountId)) {
            emails.length
                ? emails += `, ${seat.accountId}`
                : emails = seat.accountId
        }
    })

    copyTextToClipboard(emails)
}
</script>

<style lang="sass" scoped>
.admin
    &__nav
        display: flex
        gap: 12px
        margin: 0 0 16px 0

    &__orders-buttons
        display: flex
        gap: 12px
        margin-left: auto

    &__nav-btn
        display: block
        padding: 10px 8px
        background: #5c827f
        border-radius: 8px
        color: white
        text-decoration: none
        cursor: pointer

        &.router-link-active
            background: #09A59A
</style>