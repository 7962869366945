import { initializeApp } from "firebase/app"
import { initializeFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyAowV6kluKVuGY3dMNsEGvpW5p5Kfa_zAk",
    authDomain: "balticexpo-e7cb2.firebaseapp.com",
    databaseURL: "https://balticexpo-e7cb2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "balticexpo-e7cb2",
    storageBucket: "balticexpo-e7cb2.appspot.com",
    messagingSenderId: "256607099740",
    appId: "1:256607099740:web:4fc7c2105bf27cdbdfbf99"
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

const db = initializeFirestore(app, {
    experimentalForceLongPolling: true
})

if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

export { db, auth }
