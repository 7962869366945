import { defineStore } from 'pinia'
import { useSeatsStore } from './seats'

export const useTooltipStore = defineStore('tooltip', {
    state: () => ({
        position: {
            x: 0,
            y: 0
        },
        status: '',
        number: null,
        price: null,
        id: null,
        visible: false
    }),
    actions: {
        update (seat, posX, posY, visible) {
            const allSeats = useSeatsStore().all
            const currentSeat = allSeats.find(item => item.id === seat.id)

            this.position.x = posX
            this.position.y = posY
            this.status = currentSeat?.status
            this.price = currentSeat?.price
            this.number = +currentSeat?.id.split('-')[1]
            this.id = currentSeat?.id
            this.visible = visible
        }
    }
})