import { calculatePrice } from './useHelpers'
import { generatePDF, getRentalAgreement, invoiceNumber } from './useInvoice'
import logo from '../assets/logo.jpg'
import * as Sentry from "@sentry/vue";

export const sendEmail = async (formData, template, files = null) => {
    const pdfBlob = await generatePDF(formData)
    const docUrl = await getRentalAgreement()

    Email.send({
        Host: "smtp.elasticemail.com",
        SecureToken: "6081c6c3-5c4c-42af-a12b-3018835de8df",
        Username: "info@balticexpo.lv",
        Password: "C67358E95CB88942F5BB20E2A725D90485B3",
        To: template === 'orderPlaced' ? 'info@balticexpo.lv' : formData.email,
        ...(template.includes('thankYou') && {Cc: 'info@balticexpo.lv'}),
        From: "info@balticexpo.lv",
        Subject: templates[template].subject,
        Body: templates[template].body(formData),
        Attachments: files && [
            {
                name: `${formData.vatEU ? 'Invoice' : 'Rēķins'} Nr. ${invoiceNumber(formData.seats)}.pdf`,
                data: pdfBlob
            },
            {
                name: "BalticEXPO-rental-agreement.doc",
                path: docUrl
            }
        ]
    }).then(message => {
        if(message !== 'OK') {
            Sentry.captureException(message, {
                msg: message,
                email: formData.email
            })
        }
    })
}

const templates = {
    thankYouEU: {
        subject: 'Booking invoice for Baltic EXPO Christmas market',
        body: formData => {
            return `
                <p style="margin: 0 0 20px 0">Dear <span style="color: #09a59a">${formData.firstName}</span>!</p>
                <p style="margin: 0 0 20px 0">Thank you for choosing <i>Baltic EXPO</i>! Your reservation for the Largest 3-day Christmas Market in Baltics is registred and is now being processed. In the attachment you will find an invoice which must be paid within 2 working days.</p>
                <p style="margin: 0 0 20px 0">In the attachment you will also find a rental agreement. If necessary, you can return it latest by 30th of November, 2024, signed electronically.</p>
                <p style="margin: 0 0 20px 0">If you have any questions that you can't find answers to in our <a href="https://www.balticexpo.lv/terms-and-conditions">TERMS AND CONDITIONS</a> or if you need additional assistance, please feel free to contact us!</p>
                <p style="margin: 0 0 20px 0">If the selected space is no longer required - please inform us by replying to this email, thus giving other traders the opportunity to choose this location.</p>
                <br>
                <p>With the kindest regards,<br>
                Team <i>Baltic EXPO Team</i></p>
                <a href="https://balticexpo.lv"><img src="${logo}" /></a>
            `
        }
    },
    thankYouLV: {
        subject: 'Rezervācijas rēķins Baltic EXPO Ziemassvētku tirdziņam',
        body: formData => {
            return `
                <p style="margin: 0 0 20px 0">Sveiki, <span style="color: #09a59a">${formData.firstName}</span>!</p>
                <p style="margin: 0 0 20px 0">Paldies, ka izvēlējāties Baltic EXPO! Jūsu rezervācija Baltijā lielākajam 3 dienu Ziemassvētku tirdziņam ir reģistrēta un pašlaik tiek apstrādāta. Pielikumā atradīsiet rēķinu, kas ir jāapmaksā 2 darba dienu laikā.</p>
                <p style="margin: 0 0 20px 0">Pielikumā atradīsiet arī nomas līgumu. Nepieciešamības gadījumā, to varat elektroniski parakstīt un nosūtīt atpakaļ ne vēlāk kā līdz 2024. gada 30. novembrim.</p>
                <p style="margin: 0 0 20px 0">Ja Jums ir kādi jautājumi, uz kuriem neizdodas atrast atbildes mūsu <a href="https://www.balticexpo.lv/terms-and-conditions">NOTEIKUMOS UN NOSACĪJUMOS</a> vai nepieciešama papildu palīdzība, droši sazinieties ar mums!</p>
                <p style="margin: 0 0 20px 0">Ja izvēlētā vieta vairs nav aktuāla - lūdzu, informējiet mūs, atbildot uz šo e-pastu, tādējādi dodot iespēju citiem tirgotājiem izvēlēties šo vietu.</p>
                <br>
                <p>Ar pozitīviem sveicieniem,<br>
                <i>Baltic EXPO Team</i> komanda</p>
                <a href="https://balticexpo.lv"><img src="${logo}" /></a>
            `
        }
    },
    orderPlaced: {
        subject: 'Order placed',
        body: formData => {
            const seats = formData.seats.map(seat => +seat.id.split('-')[1]).toString()
            return `
                <h3 style="margin: 0 0 20px 0">New order recevied:</h3>
                <p><strong>Seats:</strong> ${seats}</p>
                <p><strong>Price:</strong> ${calculatePrice(formData.seats)}&euro;</p>
                <p><strong>Email:</strong> ${ formData.email }</p>
                <p><strong>Name:</strong> ${ formData.firstName } ${ formData.lastName }</p>
                <p><strong>Phone:</strong> ${ formData.phone }</p>
                <p><strong>Company:</strong> ${ formData.company || '-' }</p>
                <p><strong>Registration Number:</strong> ${ formData.registration || '-' }</p>
                <p><strong>Product Description:</strong> ${ formData.description || '-' }</p>
                <p><strong>EU VAT Payer:</strong> ${formData.vatEU ? 'Yes' : 'No'}</p>
                <p><strong>Comments:</strong> ${ formData.comments || '-' }</p>
            `
        }
    }
}