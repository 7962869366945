<template>
    <div class="login">
        <div class="container">
            <div class="login__nest">
                <h2>Login</h2>

                <form class="form" @submit.prevent="Login">
                    <div class="form__field form__field--input">
                        <label for="email">Email</label>
                        <input type="email" id="email" v-model="email">
                    </div>

                    <div class="form__field form__field--input">
                        <label for="password">Password</label>
                        <input type="password" id="password" v-model="password">
                    </div>

                    <app-button class="login__button">Log In</app-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import AppButton from '../../components/Button.vue'
import { useUserStore } from '../../store/user'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

const userStore = useUserStore()
const router = useRouter()

const email = ref('')
const password = ref('')
const error = ref(null)

const Login = async () => {
    try {
        await userStore.logIn(email.value, password.value)
        router.push({ name: 'admin' })
    }
    catch (err) {
        console.log(err)
        error.value = err.message
    }
}
</script>

<style lang="sass" scoped>
.login
    &__nest
        border: 1px solid #EFEFEF
        padding: 32px 16%
        border-radius: 16px
        background: #FFFFFF

    &__button
        margin-left: auto

    p
        font-weight: 600
</style>