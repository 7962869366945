<template>
    <app-header v-if="showHeader" />

    <main v-if="loaded">
        <router-view></router-view>
    </main>
</template>

<script setup>
import appHeader from './components/Header.vue'
import { useUserStore } from './store/user'
import { useSeatsStore } from './store/seats'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

const userStore = useUserStore()
const seatsStore = useSeatsStore()
const route = useRoute()

let loaded = ref(false)
let showHeader = ref(false)

const connected = computed(() => {
  return state.connected;
})

onMounted(async () => {
    await userStore.getUser()
    await seatsStore.getSeats()

    loaded.value = true
    showHeader.value = route.name !== 'landing'
})
</script>

<style lang="sass">
*,
*::before,
*::after
    box-sizing: border-box
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd
    margin: 0
ul[role='list'],
ol[role='list']
    list-style: none
html
    &:focus-within
        scroll-behavior: smooth
body
    min-height: 100vh
    text-rendering: optimizeSpeed
    background: #F5F7F9
    touch-action: pan-y
a
    &:not([class])
        text-decoration-skip-ink: auto
img,
picture
    max-width: 100%
    display: block

input,
button,
textarea,
select
    font: inherit

:root
    --available: #C1EFCB
    --reserved: #FAECB7
    --selected: #09A59A
    --disabled: #D1D5DB
    --booked: #FFB5A5

#app
    font-family: 'Montserrat', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 16px
    line-height: 20px
    color: #000

h2
    font-size: 18px
    line-height: 22px
    margin: 0 0 24px 0

.container
    width: 1200px
    max-width: 100%
    padding: 0 20px
    margin: 0 auto
    position: relative

    &--full
        width: 100%

.row
    display: flex
    align-items: center

.form
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: space-between

    &__field
        margin: 0 0 32px 0

        &--input
            width: calc(50% - 12px)
            @media only screen and (max-width: 767px)
                width: 100%

        &--textarea
            width: 100%

        &--toggle
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            width: 100%

            label:first-child
                margin: 0

            & > label > a
                text-decoration: none
                color: black

        input:not([type='checkbox']),
        textarea,
        select
            display: block
            width: 100%
            border: 1px solid #D1D5DB
            border-radius: 6px
            outline: none
            &.error
                border: 1px solid #ff6161

        input:not([type='checkbox']),
        select
            height: 41px
            padding: 0 16px

        input[type="checkbox"]
            height: 20px
            margin: 0

        textarea
            height: 115px
            min-height: 115px
            padding: 8px 16px
            resize: vertical

        label
            display: table
            max-width: calc(100% - 30px)
            font-weight: 500
            color: #3A3A3A
            margin: 0 0 8px 0
            cursor: pointer

            &.error
                color: #ff6161

                a
                    color: #ff6161

            span
                font-weight: 400
                color: #9C9C9C

    &__info
        width: 100%
        font-size: 10px
        line-height: normal
        font-weight: 400
        color: #9C9C9C
        margin: 6px 0 0 0
</style>
