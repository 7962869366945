import { defineStore } from 'pinia'
import { collection, doc, setDoc, getDocs, getDoc, updateDoc } from "firebase/firestore"
import { db } from '../firebase'

export const useSeatsStore = defineStore('seats', {
    state: () => ({
        all: [],
        selected: []
    }),
    getters: {
        selectedSeat: (state) => {
            return (seatId) => state.selected.find(seat => seat.id === seatId)
        },
        priceTotal (state) {
            let sum = state.selected.reduce((total, item) => {
                return total + (+item.price)
            }, 0)

            return sum
        },
    },
    actions: {
        setSeats (seats) {
            seats.forEach(async seat => {
                const data = {
                    id: seat.id,
                    status: 'available',
                    blocked: false,
                    price: 99,
                    accountId: ''
                }

                try {
                    await setDoc(doc(db, 'seats', seat.id), data)
                } catch (error) {
                    console.log(error)
                }
            })
        },
        async getSeats () {
            try {
                const querySnapshot = await getDocs(collection(db, 'seats'))

                querySnapshot.forEach(doc => {
                    this.all.push(doc.data())
                })
            } catch (error) {
                console.log(error)
            }

            return
        },
        assignSeats () {
            this.all.forEach(seat => {
                const seatElem = document.getElementById(seat.id)
                const seatNumber = +seat.id.split('-')[1]
                const seatNumberElem = seatElem.querySelector('tspan')
                const seatNumberParent = seatElem.querySelector('text')

                if (seat.blocked) {
                    seatElem.classList.add('blocked')
                }

                seatElem.classList.add(seat.status)
                seatNumberElem.innerHTML = seatNumber
                seatNumberParent.classList.add('show')
            })
        },
        async checkSeat (seatId) {
            const seatRef = doc(db, 'seats', seatId);
            const seatDoc = await getDoc(seatRef);
            return seatDoc.data();
        },
        async updateSeat (seat) {
            const seatRef = doc(db, 'seats', seat.id)
            const seatElem = document.getElementById(seat.id)
            let updatedSeat = this.all.find(item => item.id === seat.id)

            try {
                await updateDoc(seatRef, seat)
                Object.assign(updatedSeat, seat)
                seatElem?.setAttribute('class', seat.status)
            } catch (error) {
                console.log(error)
            }

            return
        },
        selectSeat (seatId) {
            if (this.selectedSeat(seatId)) {
                this.selected = this.selected.filter(item => item.id !== seatId)
                document.getElementById(seatId).classList.remove('selected')

                return
            }

            this.selected.push(this.all.find(item => item.id === seatId))
            document.getElementById(seatId).classList.add('selected')
        },
        resetSelectedSeats () {
            this.selected.forEach(seat => {
                document.getElementById(seat.id)?.classList.remove('selected')
            })
            this.selected = []
        }
    }
})