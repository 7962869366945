/**
 * Returns true if device is mobile
 * @returns
 */
export const isMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    && !window.MSStream) {
        return true
    }
    return false
}

/**
 * Groups items with same key value
 * @param {*} arr
 * @param {*} key
 * @returns
 */
export const splitArrayByKey = (arr, key) => {
    const result = {}

    arr.forEach(obj => {
        const value = obj[key]

        if (result[value]) {
            result[value].push(obj)
            return
        }

        result[value] = [obj]
    })

    return Object.values(result);
}

/**
 * Calculate price total
 * @param {*} seats
 * @returns
 */
export const calculatePrice = seats => {
    let total = 0;

    seats.map(seat => {
        total += Number(seat.price)
    })

    return total
}

/**
 * Copy text to clipboard
 * @param {*} text
 */
export const copyTextToClipboard = text => {
    navigator.clipboard.writeText(text)
}