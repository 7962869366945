<template>
    <div class="admin-seats">
        <div class="row">
            <div class="admin-seats__nest">
                <floor-plan :showTooltip="false" @seatClicked="seatClicked" />
            </div>

            <div class="admin-seats__sidebar">
                <h2>{{ selectedSeat?.id ? `Seat #${+selectedSeat.id.split('-')[1]}` : 'Select a seat' }}</h2>
                <form action="" class="form">
                    <div class="form__field">
                        <label>Booked By</label>
                        <input type="text" v-model="selectedSeat.accountId">
                    </div>

                    <div class="form__field">
                        <label for="status">Status</label>
                        <select name="" id="status" v-model="selectedSeat.status" :disabled="!selectedSeat.status">
                            <option value="" disabled>-</option>
                            <option value="available">Available</option>
                            <option value="reserved">Reserved</option>
                            <option value="booked">Booked</option>
                        </select>
                    </div>

                    <div class="form__field">
                        <label for="status">Price</label>
                        <input type="text" id="price" v-model.number="selectedSeat.price" :disabled="!selectedSeat.price">
                    </div>

                    <app-button @click="saveSeat">Save</app-button>
                </form>
            </div>
        </div>
    </div>
</template>



<script setup>
import FloorPlan from '../../components/FloorPlan.vue'
import AppButton from '../../components/Button.vue'
import { useSeatsStore } from '../../store/seats'
import { reactive } from 'vue'

const seats = useSeatsStore()

let selectedSeat = reactive({})

const seatClicked = seatId => {
    seats.resetSelectedSeats()
    seats.selectSeat(seatId)
    Object.assign(selectedSeat, seats.selectedSeat(seatId))
}

const saveSeat = e => {
    e.preventDefault()
    seats.updateSeat(selectedSeat)
}
</script>

<style lang="sass" scoped>
.admin-seats
    .row
        align-items: flex-start
        gap: 16px

    &__nest,
    &__sidebar
        border: 1px solid #EFEFEF
        border-radius: 16px
        background: #FFFFFF

    &__nest
        max-width: calc(100% - 416px)
        height: calc(100vh - 131px)

    &__plan
        height: 100%
        overflow: auto
        scrollbar-width: none
        -ms-overflow-style: none

        &::-webkit-scrollbar
            display: none

    &__sidebar
        min-width: 400px
        min-height: calc(100vh - 131px)
        padding: 32px

    .form
        &__field
            width: 100%

        button
            margin-left: auto
</style>