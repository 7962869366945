<template>
    <div class="booking">
        <div class="container">
            <div class="booking__nest">
                <router-link :to="{ name: 'home' }" class="back-btn">
                    &#8249; Back
                </router-link>
                <h2>Booking information</h2>
                <form class="form" ref="form">
                    <div class="form__field form__field--input">
                        <label for="first-name">First name</label>
                        <input :class="{'error': v$.firstName.$error}" type="text" id="first-name" v-model="formData.firstName" required>
                        <span class="input__error-message" v-if="v$.firstName.$error">
                            Please enter your name
                        </span>
                    </div>

                    <div class="form__field form__field--input">
                        <label for="last-name">Last name</label>
                        <input :class="{'error': v$.lastName.$error}" type="text" id="last-name" v-model="formData.lastName" required>
                        <span class="input__error-message" v-if="v$.lastName.$error">
                            Please enter your last name
                        </span>
                    </div>

                    <div class="form__field form__field--input">
                        <label for="phone-number">Phone number</label>
                        <input :class="{'error': v$.phone.$error}" type="text" id="phone-number" v-model="formData.phone" required>
                        <span class="input__error-message" v-if="v$.phone.$error">
                            Please enter your phone no.
                        </span>
                    </div>

                    <div class="form__field form__field--input">
                        <label for="email">E-mail</label>
                        <input :class="{'error': v$.email.$error}" type="email" id="email" v-model="formData.email" required>
                        <span class="input__error-message" v-if="v$.email.$error">
                            Please enter a valid email address
                        </span>
                    </div>

                    <div class="form__field form__field--input">
                        <label for="company-name">Company name</label>
                        <input :class="{'error': v$.company.$error}" type="text" id="company-name" v-model="formData.company" required>
                        <span class="input__error-message" v-if="v$.company.$error">
                            Please enter your company name
                        </span>
                    </div>

                    <div class="form__field form__field--input">
                        <label for="registration-number">Registration number</label>
                        <input :class="{'error': v$.registration.$error}" type="text" id="registration-number" v-model="formData.registration" required>
                        <span class="input__error-message" v-if="v$.registration.$error">
                            Please enter your registration no.
                        </span>
                    </div>

                    <div class="form__field form__field--textarea">
                        <label for="product-description">Product description</label>
                        <textarea :class="{'error': v$.description.$error}" id="product-description" v-model="formData.description" required></textarea>
                        <span class="input__error-message" v-if="v$.description.$error">
                            Please describe your product
                        </span>
                    </div>

                    <div class="form__field form__field--toggle">
                        <label :class="{'error': v$.terms.$error}">I have read the <a href="https://www.balticexpo.lv/terms-and-conditions" target="_blank">TERMS AND CONDITIONS</a>, and agree to them.</label>
                        <div class="checkbox-wrapper-2">
                            <input type="checkbox" class="sc-gJwTLC ikxBAC" id="terms" v-model="formData.terms" required>
                        </div>
                    </div>

                    <div class="form__field form__field--toggle">
                        <label for="vatEU"><b>!NOT FOR LATVIA!</b> I confirm that our company is a VAT payer in Lithuania, Estonia, or any other EU country (<b>!NOT Latvia!</b>) and shall be a subject to reverse charge*</label>
                        <div class="checkbox-wrapper-2">
                            <input type="checkbox" class="sc-gJwTLC ikxBAC" id="vatEU" v-model="formData.vatEU">
                        </div>
                        <div class="form__info">
                            *VAT Directive 2006/112/EC Art.44, Art.196<br> Subject to taxes (VAT) in the receiver's country.<br> Reverse charge 
                        </div>
                    </div>

                    <div class="form__field form__field--textarea">
                        <label for="additional-comments">Additional comments <span>(optional)</span></label>
                        <textarea id="additional-comments" v-model="formData.comments"></textarea>
                    </div>
                </form>

                <div class="booking__total">
                    <div class="booking__prices">
                        <div class="booking__price-details" v-for="(price, index) in prices" :key="index">
                            {{ price.length }} x {{ price[0].price }} EUR <br/>
                            <span class="id-list" v-for="(spot, index) in price" :key="index"> {{ getId(spot) }}&nbsp;</span><span>|&nbsp;3x2 m</span>
                        </div>
                    </div>
                    <div class="booking__price-total" v-if="formData.vatEU">
                        TOTAL: &euro;{{ seats.priceTotal.toFixed(2) }}
                    </div>
                    <div class="booking__price-total" v-else>
                        <span class="booking__price-vat">VAT 21%: {{ Math.round(seats.priceTotal * 0.21 * 100) / 100 }}<br></span>
                        TOTAL: &euro;{{ Math.round(seats.priceTotal * 1.21 * 100) / 100 }}
                    </div>
                </div>

                <app-button @click="submit" v-if="!loader">Confirm my booking</app-button>
                <app-button @click="submit" v-if="loader"><div id="loading"></div></app-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import AppButton from '../../components/Button.vue'
import { useSeatsStore } from '../../store/seats'
import { useOrdersStore } from '../../store/orders'
import { splitArrayByKey } from '../../composables/useHelpers'
import { sendEmail } from '../../composables/useEmail'
import { useRouter } from 'vue-router'
import { reactive, ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'

const router = useRouter()
const seats = useSeatsStore()
const selectedSeats = seats.selected
const orders = useOrdersStore()
const form = ref(null)
const prices = computed(() => splitArrayByKey(selectedSeats, 'price'))
const loader = ref(false)

const formData = reactive({
    date: Date.now(),
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
    registration: '',
    description: '',
    terms: false,
    vatEU: false,
    comments: '',
    seats: selectedSeats
})

const rules = {
    firstName: {required},
    lastName: {required},
    phone: {required},
    email: {required, email},
    company: {required},
    registration: {required},
    description: {required},
    terms: {required, sameAs: sameAs(true)}
}

const v$ = useVuelidate(rules, formData);

const submit = async () => {
    const result = await v$.value.$validate();

    if (result) {
        loader.value = true

        try {
            const checkSeatsPromises = selectedSeats.map(async seat => {
                const status = await seats.checkSeat(seat.id);
                return status;
            });
            const seatsStatus = await Promise.all(checkSeatsPromises);

            if (seatsStatus.some(status => status.status !== 'available')) {
                throw 'One or more seats are already reserved!';
            }

            selectedSeats.forEach(async seat => {
                seat.accountId = formData.email
                seat.status = 'reserved'
                await seats.updateSeat(seat)
            })

            await orders.addOrder(formData)

            Promise.all([
                sendEmail(formData, `thankYou${formData.vatEU ? 'EU' : 'LV'}`, true),
                sendEmail(formData, 'orderPlaced')
            ]).then(() => {
                router.push({ name: 'thank-you' })
            })
        } catch (error) {
            loader.value = false
            router.push({ name: 'home', query: { from: 'bookingError' } }).then(() => location.reload())
        }
    }
}

function getId(spot) {
    return spot.id.replace("spot-", "#");
}

</script>

<style lang="sass" scoped>
.booking
    &__nest
        border: 1px solid #EFEFEF
        padding: 32px 16%
        border-radius: 16px
        background: #FFFFFF

    &__prices
        display: flex
        flex-flow: column
        gap: 16px

    &__total
        display: flex
        align-items: flex-end
        justify-content: space-between
        padding: 12px 16px
        margin: 0 0 20px 0
        border-radius: 8px
        background: #F5F7F9

        @media only screen and (max-width: 767px)
            display: block

    &__price-details
        font-size: 14px
        font-weight: 500
        color: #000

        span
            // display: block
            font-size: 10px
            line-height: 12px
            color: #7E7E7E

    &__price-total
        font-weight: 600
        text-align: right

        @media only screen and (max-width: 767px)
            margin-top: 30px

    &__price-vat
        font-weight: normal
        font-size: 12px

.input__error-message
    color: #ff6161

.input-field__error
    border: 1px solid #ff6161

.checkbox-wrapper-2
  .ikxBAC
    appearance: none
    background-color: #dfe1e4
    border-radius: 72px
    border-style: none
    flex-shrink: 0
    height: 20px
    margin: 0
    position: relative
    width: 30px

    &::before
      bottom: -6px
      content: ""
      left: -6px
      position: absolute
      right: -6px
      top: -6px

    transition: all 100ms ease-out

    &::after
      transition: all 100ms ease-out
      background-color: #fff
      border-radius: 50%
      content: ""
      height: 14px
      left: 3px
      position: absolute
      top: 3px
      width: 14px

  input[type=checkbox]
    cursor: pointer

  .ikxBAC
    &:hover
      background-color: #c9cbcd
      transition-duration: 0s

    &:checked
      background-color: #09A59A

      &::after
        background-color: #fff
        left: 13px

  :focus:not(.focus-visible)
    outline: 0

  .ikxBAC:checked:hover
    background-color: #09A59A

.back-btn
    color: #09A59A
    text-decoration: none
    margin-bottom: 20px
    display: block

#loading
  display: inline-block
  width: 26px
  height: 26px
  border: 3px solid rgba(255, 255, 255, 0.3)
  border-radius: 50%
  border-top-color: #fff
  margin: 10px 50px -2px
  animation: spin 1s ease-in-out infinite
  -webkit-animation: spin 1s ease-in-out infinite

@keyframes spin
  to
    -webkit-transform: rotate(360deg)

@-webkit-keyframes spin
  to
    -webkit-transform: rotate(360deg)



</style>