<template>
    <div class="thank-you">
        <div class="container">
            <div class="thank-you__nest">
                <h2>Thank you! <div class="thick-check-mark"></div></h2>
                <p>Your registration for the place(s) has been received.</p>
                <p>Soon you will receive an invoice and further information in your email!</p>
                <a href="https://balticexpo.lv">
                    <app-button>Return home</app-button>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import AppButton from '../../components/Button.vue'
</script>

<style lang="sass" scoped>
.thank-you
    &__nest
        display: flex
        align-items: center
        justify-content: center
        min-height: 287px
        text-align: center
        border-radius: 16px
        background: #fff
        flex-direction: column
    h2
        font-size: 24px
    p
        margin: 6px 0

    a
        margin-top: 20px

.thick-check-mark
    width: 16px
    height: 28px
    border: 6px solid #09A59A
    border-left: 0
    border-top: 0
    transform: rotate(45deg)
    display: inline-block
    margin-left: 12px
</style>