<template>
    <div class="home">
        <div class="container container--full">
            <div class="alert" v-show="showError">
                <span class="closebtn" @click="hideError">&times;</span>
                <b>Looks like someone got there faster!</b> <br/>
                The place you chose was reserved. Have a look at other available options.
                <br/>
                <button @click="hideError" class="error-button">SELECT NEW PLACE</button>
            </div>
            <div class="home__plan-nest">
                <floor-plan />
                <div class="home__legend">
                    <p class="home__legend-item home__legend-item--available">Available</p>
                    <p class="home__legend-item home__legend-item--reserved">Reserved</p>
                    <p class="home__legend-item home__legend-item--booked">Booked</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="home__summary">
                <div>
                    <p>{{ seats.selected.length }} place{{ seats.selected.length > 1 ? 's' : '' }} selected</p>
                    <p><strong>Total price: {{ seats.priceTotal }}&euro;</strong></p>
                    <a class="reset-btn" v-if="seats.selected.length > 0" @click="seats.resetSelectedSeats()">&#10006; Clear selection</a>
                </div>

                <router-link :class="{'disabled': !seats.selected.length > 0}" :to="{ name: 'booking' }">
                    <app-button>Continue &#8594;</app-button>
                </router-link>
            </div>
        </div>
    </div>
    <l-modal :name="'test'" />
</template>

<script setup>
    import FloorPlan from '../../components/FloorPlan.vue'
    import AppButton from '../../components/Button.vue'
    import { useSeatsStore } from '../../store/seats'
    import { onMounted, ref } from 'vue'
    import { useRouter } from 'vue-router'

    const seats = useSeatsStore()
    const plan = document.querySelector('.home__plan')
    const router = useRouter()
    const showError = ref(false)

    const hideError = () => {
        showError.value = false
        router.push({ name: 'home' })
    }  

    onMounted(() => {
        if (router.currentRoute.value.query.from === 'bookingError') {
            showError.value = true
        }
    })

</script>

<style scoped lang="sass">

.home
    &__plan-nest
        position: relative
        height: calc(100vh - 220px)
        border: 1px solid #EFEFEF
        border-radius: 16px
        background: #FFFFFF

    &__legend
        display: flex
        align-items: center
        gap: 10px
        position: absolute
        bottom: 16px
        left: 16px
        padding: 8px
        border-radius: 4px
        background: #FFFFFF
        box-shadow: 1px 1px 8px rgba(41, 41, 41, 0.35)

    &__legend-item
        font-size: 14px
        line-height: 24px
        font-weight: 500
        padding: 0 6px

        &--available
            background: var(--available)

        &--reserved
            background: var(--reserved)

        &--booked
            background: var(--booked)

    &__summary
        display: flex
        align-items: flex-start
        justify-content: space-between
        margin: 8px 0 0 0

        p
            color: #777

        strong
            color: #000

a.disabled
    opacity: .2
    pointer-events: none

.reset-btn
    margin-top: 8px
    color: #818181
    cursor: pointer
    display: block
    font-size: 12px
    @media only screen and (max-width: 767px)
        margin-top: 2px

.alert
  padding: 20px
  background-color: #f44336
  color: white
  margin-bottom: 15px
  border-radius: 10px
  position: absolute
  z-index: 10
  width: 90%
  max-width: 500px

.closebtn
  margin-left: 15px
  color: white
  font-weight: bold
  float: right
  font-size: 22px
  line-height: 20px
  cursor: pointer
  transition: 0.3s

.closebtn:hover
  color: black

.error-button
    margin-top: 12px 
    color: black 
    background-color: white
    border: 0
    outline: 0
    border-radius: 5px
    padding: 8px 16px
    cursor: pointer
    &:hover
        background-color: #EEE

</style>
