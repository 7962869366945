import { defineStore } from 'pinia'
import { collection, doc, addDoc, getDocs, updateDoc, query, orderBy } from "firebase/firestore"
import { db } from '../firebase'

export const useOrdersStore = defineStore('orders', {
    state: () => ({
        orders: [],
        showAll: false
    }),
    actions: {
        async readOrders () {
            if (this.orders.length) {
                return
            }

            try {
                const ordersCollection = collection(db, "orders");
                const querySnapshot = await getDocs(query(ordersCollection, orderBy('date', 'desc')))

                querySnapshot.forEach(doc => {
                    const order = {
                        id: doc.id,
                        ...doc.data()
                    }

                    this.orders.push(order)
                })
            } catch (error) {
                console.log(error)
            }
        },
        async addOrder (order) {
            try {
                await addDoc(collection(db, 'orders'), order)
            } catch (error) {
                console.log(error)
            }
        },
        async updateOrder (order) {
            const orderRef = doc(db, 'orders', order.id)

            try {
                await updateDoc(orderRef, order)
            } catch (error) {
                console.log(error)
            }

            return
        },
    }
})